import HighmarkIcon from '../assets/allmyhealth-logo.svg';
import ErrorIcon from '../assets/error-icon.svg';
import HighmarkEmptyData from '../assets/list-empty.svg';

const CUSTOMER_SUPPORT_ASSETS = {
  LOGO_ICON: HighmarkIcon,
  EMPTY_ICON: HighmarkEmptyData,
};
const WEB_COMMON_ASSETS = {
  WEB_COMMON_ERROR_STATE_IMAGE: ErrorIcon,
};

export const ASSETS_CONFIG = {
  ...CUSTOMER_SUPPORT_ASSETS,
  ...WEB_COMMON_ASSETS,
};
